/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

const links = [
  { to: `/accessibility-statement/`, text: `Accessibility Statement` },
  { to: `/contributing/code-of-conduct/`, text: `Code of Conduct` },
  { to: `/guidelines/logo/`, text: `Logo & Assets` },
  { to: `/privacy-policy/`, text: `Privacy Policy` },
]

const listStyles = {
  background: props => (props.bg ? props.bg : `0`),
  borderColor: `standardLine`,
  borderTopStyle: `solid`,
  borderTopWidth: `1px`,
  fontSize: 1,
  display: "flex",
  flexDirection: ["column", null, null, null, "row"],
  justifyContent: "center",
  flexWrap: "wrap",
  listStyle: `none`,
  m: 0,
  mb: props => (props.bottomMargin ? props.bottomMargin : 0),
  mt: 10,
  px: 7,
  py: 10,
  textAlign: `center`,
  width: `100%`,
}

const linkStyles = {
  color: `grey.70`,
  borderColor: `transparent`,
  "&:hover": {
    color: `gatsby`,
    borderColor: `link.hoverBorder`,
  },
}

const listItemStyles = {
  flexBasis: ["auto", null, null, null, "200px"],
}

const FooterLinks = () => (
  <footer>
    <ul sx={listStyles}>
      {links.map(({ to, text }) => (
        <li key={to} sx={listItemStyles}>
          <Link to={to} sx={linkStyles}>
            {text}
          </Link>
        </li>
      ))}
    </ul>
  </footer>
)

export default FooterLinks
